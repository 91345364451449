<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card max-width="600px" class="mx-auto my-12 px-6 py-4" elevation="6">
      <v-card-title>测评步骤</v-card-title>
      <v-card-text>
        <v-stepper vertical class="stepper-flat" v-model="currentStep">
          <template v-for="(lb, index) in stepperLbList">
            <v-stepper-step
              :key="`${lb.lbGuid}-step`"
              :step="index + 1"
              :complete="currentStep > index + 1"
              :rules="[
                () => lb.testStatus !== 'cancelled' && lb.testStatus !== 'saved'
              ]"
            >
              {{ lb.lbDispName }}
              <small
                v-if="lb.testStatus === 'submitted'"
                :key="lb.lbGuid"
                class="mt-3"
              >
                测试已成功提交
              </small>
              <small
                v-else-if="lb.testStatus === 'saved'"
                :key="lb.lbGuid"
                class="mt-3"
              >
                测试进度已保存
              </small>
              <small
                v-else-if="lb.testStatus === 'cancelled'"
                :key="lb.lbGuid"
                class="mt-3"
              >
                测试被中止
              </small>
            </v-stepper-step>
            <v-stepper-content :key="`${lb.lbGuid}-content`" :step="index + 1">
              <v-card flat class="mr-6">
                <v-card-text>点击开始进行：{{ lb.lbDispName }}</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    depressed
                    @click="startNextTest(lb.lbGuid)"
                  >
                    开始答题
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </template>
          <v-stepper-step :step="totalLbCount + 1">
            {{ isShowScaReport ? "查看报告" : "完成" }}
          </v-stepper-step>
          <v-stepper-content :step="totalLbCount + 1">
            <v-card flat class="mr-6">
              <v-card-text>
                恭喜你完成了所有测试！
                {{ isShowScaReport ? "点击查看报告或直接退出" : "点击退出" }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <template v-if="isShowScaReport">
                  <v-btn color="primary" depressed @click="watchScaReport">
                    查看报告
                  </v-btn>
                  <v-btn color="red" text @click="isShowConfirmDialog = true">
                    退出
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn color="success" text @click="exitConfirmed">
                    退出
                  </v-btn>
                </template>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
    <AppDialog
      v-model="isShowConfirmDialog"
      size="small"
      title="确定要退出吗？"
      color="error"
      action-text="退出"
      @confirm="exitConfirmed"
    >
      答题结果已全部保存，您可以安全退出
    </AppDialog>
    <AppMessageBox title="发生错误" v-model="errorMsg" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppDialog from "@/components/AppDialog";
import AppMessageBox from "@/components/AppMessageBox";
import { fetchIsShowScaReport } from "@/api/sca";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AppLoadingSpinner,
    AppDialog,
    AppMessageBox
  },

  data() {
    return {
      isLoading: false,
      isShowConfirmDialog: false,
      isShowScaReport: false,
      errorMsg: ""
    };
  },

  computed: {
    ...mapGetters({
      groupGuid: "sca/groupGuid",
      testLbList: "sca/testLbList",
      submittedCaseList: "sca/submittedCaseList",
      savedCaseList: "sca/savedCaseList",
      cancelledCaseList: "sca/cancelledCaseList"
    }),
    currentStep() {
      return (
        this.submittedCaseList.length +
        this.cancelledCaseList.length +
        this.savedCaseList.length +
        1
      );
    },
    totalLbCount() {
      return this.testLbList.length;
    },
    stepperLbList() {
      return this.testLbList.map(lb => {
        let status = "pending";
        if (this.submittedCaseList.find(sub => sub.lbDispName === lb.text)) {
          status = "submitted";
        } else if (this.savedCaseList.find(sub => sub.lbDispName === lb.text)) {
          status = "saved";
        } else if (
          this.cancelledCaseList.find(sub => sub.lbDispName === lb.text)
        ) {
          status = "cancelled";
        }
        return {
          lbDispName: lb.text,
          lbGuid: lb.value,
          testStatus: status
        };
      });
    }
  },

  methods: {
    ...mapActions({
      setCurrentLbGuid: "sca/setCurrentLbGuid",
      clearTimeCost: "sca/clearTimeCost"
    }),
    async getIsShowScaReport() {
      try {
        this.isLoading = true;
        let showReport = await fetchIsShowScaReport(this.groupGuid);
        this.isShowScaReport = showReport && this.submittedCaseList.length;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    startNextTest(lbGuid) {
      this.clearTimeCost();
      this.setCurrentLbGuid(lbGuid);
      this.$router.push({ name: "scatestertext" });
    },
    watchScaReport() {
      this.$router.push({ name: "scareport" });
    },
    exitConfirmed() {
      this.$store.dispatch("sca/clearScaState");
      this.isShowConfirmDialog = false;
      this.$router.push({ name: "scalogin" });
    }
  },

  created() {
    this.getIsShowScaReport();
  }
};
</script>

<style lang="scss" scoped>
.stepper-flat {
  box-shadow: unset;
}
</style>
